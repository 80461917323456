const questionAdapter = (data) => {
  return {
    answer: data.answer,
    question: data.question,
  };
};

export default ({ data }) => {
  return {
    questions: data.questions?.map(questionAdapter),
  };
};
